<script>
/* COMPOSANTS */
import AkFormView from "@components/layout/AkFormView";
import AkInputText from "@components/input/AkInputText";
import AkInputNumber from "@components/input/AkInputNumber";

/* MIXINS */
import randomRef from "@mixins/randomRef";
import roleMixin from "@mixins/roleMixin";

/* SERVICES */
import bilanCondTravailService from "@services/bilanCondTravailService";

export default {
  components: {AkFormView, AkInputText, AkInputNumber},
  mixins: [randomRef, roleMixin],
  metaInfo() {
    return {
      title: "bilan.details",
    }
  },
  data() {
    return {
      current: {},
    }
  },
  mounted() {
    this.getRef().showTotalLoader();

    let msg = this.$route.query.msg;
    if (msg && msg !== "") {
      this.getRef().success(msg);
    }

    let p1 = bilanCondTravailService.findById(this.$route.params.id);
    p1.then(data => this.current = data);

    Promise.all([p1]).then(()=>{
      this.getRef().hideLoader();
    });
  },
  methods: {},
  computed: {
    isSingleWeek() {
      return this.current.weekStart === this.current.weekEnd;
    }
  }
}
</script>

<template v-if=!roleLoading>
  <AkFormView :ref="ref" :title="$t('bilan.details')">
    <template v-slot:action>
      <RouterLink v-if="current.id !== undefined && this.canEditHealthcare() && !current.year && this.canEditOrDeleteReview(current)"
                  :to="{ name: 'bilanCondTravailUpdate', params: {id: current.id}}"
                  class="btn btn-inverse-primary btn-xs"><i
        class="fe fe-edit pr-1"/>{{ $t('update') }}
      </RouterLink>
    </template>
    <template v-slot:form>
      <div class="form-row" v-if="current.createdById !== null">
        <AkInputText :disabled=true v-model=current.author class-name="col-md-4" :label="$t('bilan.author')"/>
      </div>
      <div class="form-row">
        <AkInputNumber :disabled=true
                       v-model="current.weekStart"
                       :label="$t(current.weekEnd !== current.weekStart ? 'bilan_cond_travail.week_start' : 'bilan_cond_travail.week')"
                       class-name="col-md-3"/>
        <AkInputNumber :disabled=true
                       v-if="current.weekEnd !== current.weekStart"
                       v-model="current.weekEnd"
                       :label="$t('bilan_cond_travail.week_end')"
                       class-name="col-md-3"/>
      </div>
      <div class="form-row">
        <AkInputText :disabled=true v-model=current.agerLabel class-name="col-md-4" :label="$t('ager_label')"/>
        <AkInputText :disabled=true v-model=current.uerLabel class-name="col-md-4" :label="$t('uer_label')"/>
        <AkInputText :disabled=true v-model=current.ceiLabel class-name="col-md-4" :label="$t('cei_label')"/>
      </div>

      <h5>{{ $t('bilan_cond_travail.title1') }}</h5>

      <div class="form-row">
        <AkInputText
            :disabled=true
            v-model="current.tauxRetourAstreinte"
            :label="$t('bilan_cond_travail.taux_retour_astreinte')"
            class-name="col-md-12"/>
      </div>
      
      <h5>{{ $t('bilan_cond_travail.title2') }}</h5>
      
      <div class="form-row">
        <AkInputText
            :disabled=true
            v-model="current.nb1"
            :label="$t('bilan_cond_travail.nb1')"
            class-name="col-md-12"/>
      </div>
      <div class="form-row">
        <AkInputNumber
            :disabled=true
            v-model="current.nb2"
            :label="$t('bilan_cond_travail.nb2')"
            class-name="col-md-12"/>
      </div>
      <div class="form-row">
        <AkInputNumber
            :disabled=true
            v-model="current.nb3"
            :label="$t('bilan_cond_travail.nb3')"
            class-name="col-md-12"/>
      </div>
      <div class="form-row">
        <AkInputNumber
            :disabled=true
            v-model="current.nb4"
            :label="$t('bilan_cond_travail.nb4')"
            class-name="col-md-12"/>
      </div>
      <div class="form-row">
        <AkInputNumber
            :disabled=true
            v-model="current.nb5"
            :label="$t('bilan_cond_travail.nb5')"
            class-name="col-md-12"/>
      </div>
      <div class="form-row">
        <AkInputNumber
            :disabled=true
            v-model="current.nb6"
            :label="$t('bilan_cond_travail.nb6')"
            class-name="col-md-12"/>
      </div>
      <div class="form-row">
        <AkInputNumber
            :disabled=true
            v-model="current.nb7"
            :label="$t('bilan_cond_travail.nb7')"
            class-name="col-md-12"/>
      </div>
      <div class="form-row">
        <AkInputNumber
            :disabled=true
            v-model="current.nb8"
            :label="$t('bilan_cond_travail.nb8')"
            class-name="col-md-12"/>
      </div>
      <div class="form-row">
        <AkInputNumber
            :disabled=true
            v-model="current.nb9"
            :label="$t('bilan_cond_travail.nb9')"
            class-name="col-md-12"/>
      </div>
      <div class="form-row">
        <AkInputNumber
            :disabled=true
            v-model="current.nb10"
            :label="$t('bilan_cond_travail.nb10')"
            class-name="col-md-12"/>
      </div>

      <h5>{{ $t('bilan_cond_travail.title3') }}</h5>
      
      <div class="form-row">
        <AkInputNumber
            :disabled=true
            v-model="current.nb11"
            :label="$t('bilan_cond_travail.nb11')"
            class-name="col-md-12"/>
      </div>
      <div class="form-row">
        <AkInputNumber
            :disabled=true
            v-model="current.meoActionRenforcee"
            :label="$t('bilan_cond_travail.meo_action_renforcee')"
            class-name="col-md-12"/>
      </div>
      <div class="form-row">
        <AkInputNumber
            :disabled=true
            v-model="current.meoOrgTravailSpec"
            :label="$t('bilan_cond_travail.meo_org_travail_spec')"
            class-name="col-md-12"/>
      </div>
      <div class="form-row">
        <AkInputNumber
            :disabled=true
            v-model="current.mepProgActivite"
            :label="$t('bilan_cond_travail.mep_prog_activite')"
            class-name="col-md-12"/>
      </div>
      <div class="form-row">
        <AkInputNumber
            :disabled=true
            v-model="current.orgParticuliereAvWe"
            :label="$t('bilan_cond_travail.org_particuliere_av_we')"
            class-name="col-md-12"/>
      </div>
    </template>
  </AkFormView>
</template>